/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background: #f7f7f7;
  overflow-x: hidden;
}

.ffsans { font-family: "Open Sans", "Arial", "Helvetica", sans-serif; }

.semibold, .semibold_a, .semibold_a * { font-weight: 600; }

span.required { color: #e55600; font-weight: 700; }

.bgcf7, .bgcf7_h:hover { background-color: #f7f7f7; }
.bgcf7_i, .bgcf7_h_i:hover { background-color: #f7f7f7 !important; }
.bgcorange, .bgcorange_h:hover { background-color: #e55600; }
.bgcorange_i, .bgcorange_h_i:hover { background-color: #e55600 !important; }
.bgcdorange, .bgcdorange_h:hover { background-color: #e36a21; }
.bgcdorange_i, .bgcdorange_h_i:hover { background-color: #e36a21 !important; }
.bgcblue, .bgcblue_h:hover { background-color: #3f72ab; }
.bgcblue_i, .bgcblue_h_i:hover { background-color: #3f72ab !important; }
.bgclblue, .bgclblue_h:hover { background-color: #0294b5; }
.bgclblue_i, .bgclblue_h_i:hover { background-color: #0294b5 !important; }
.bgcgreen, .bgcgreen_h:hover { background-color: #74c601; }
.bgcgreen_i, .bgcgreen_h_i:hover { background-color: #74c601 !important; }
.bgclgreen, .bgclgreen_h:hover { background-color: #8bd826; }
.bgclgreen_i, .bgclgreen_h_i:hover { background-color: #8bd826 !important; }

.corange, .corange_h:hover { color: #e55600; }
.corange_i, .corange_h_i:hover { color: #e55600 !important; }
.cblue, .cblue_h:hover { color: #3f72ab; }
.cblue_i, .cblue_h_i:hover { color: #3f72ab !important; }
.clblue, .clblue_h:hover { color: #0294b5; }
.clblue_i, .clblue_h_i:hover { color: #0294b5 !important; }
.cgreen, .cgreen_h:hover { color: #74c601; }
.cgreen_i, .cgreen_h_i:hover { color: #74c601 !important; }
.clgreen, .clgreen_h:hover { color: #8bd826; }
.clgreen_i, .clgreen_h_i:hover { color: #8bd826 !important; }

.brcorange, .brcorange_h:hover { border-color: #e65600; }
.brcorange_i, .brcorange_h_i:hover { border-color: #e65600 !important; }
.brcblue, .brcblue_h:hover { border-color: #3f72ab; }
.brcblue_i, .brcblue_h_i:hover { border-color: #3f72ab !important; }
.brclblue, .brclblue_h:hover { border-color: #0294b5; }
.brclblue_i, .brclblue_h_i:hover { border-color: #0294b5 !important; }
.brcgreen, .brcgreen_h:hover { border-color: #74c601; }
.brcgreen_i, .brcgreen_h_i:hover { border-color: #74c601 !important; }
.brclgreen, .brclgreen_h:hover { border-color: #8bd826; }
.brclgreen_i, .brclgreen_h_i:hover { border-color: #8bd826 !important; }

p { margin-bottom: 22px; }
p + p { margin-top: -8px; }
.subheader + p, .subsubheader + p, .subsubsubheader + p, .subsubsubheader-min + p { margin-top: -4px; }

.link {
  color: #3f72ab;
  text-decoration: underline;
  cursor: pointer;
}
  .link:hover {
    text-decoration: none;
  }


.form-block-container {
  background: #fff;
  padding: 40px;
}
  .form-popup-container .form-block-container {
    min-width: 420px;
    max-width: 700px;
  }

  .form {
    position: relative;
  }
    .form-row + .form-row {
      margin-top: 22px;
    }
      .form-label {
        margin-bottom: 6px;
      }
        .form-row-checkbox .form-label {
          font-size: 14px;
          margin: 0px 0px 0px 10px;
          cursor: pointer;
        }
          .form-row-checkbox .form-label label {
            cursor: pointer;
          }

    .form-row-checkbox .form-field-wrapper {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
      .form-row-checkbox .form-field input {
        display: none;
      }
      .form-row-checkbox .form-field > span {
        content: "";
        color: transparent;
        font-size: 0px;
        line-height: 0px;
        display: block;
        width: 22px;
        min-width: 22px;
        height: 22px;
        border: 2px solid #0294b5;
        position: relative;
        cursor: pointer;
      }
        .form-row-checkbox .form-field > span label {
          content: "";
          color: transparent;
          display: block;
          font-size: 0px;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          margin: auto;
          cursor: pointer;
        }
          .form-row-checkbox .form-field > span label:after {
            content: "";
            display: none;
            border: solid #0294b5;
            border-width: 0px 2px 2px 0px;
            width: 4px;
            height: 9px;
            position: absolute;
            top: -3px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            margin: auto;
            transform: rotate(45deg);
          }
            .form-row-checkbox .form-field input:checked + span label:after {
              display: block;
            }

      .input-container {
        background-color: #fff;
        border: 1px solid #f2f2f2;
        border-radius: 8px;
        padding: 7px 10px 6px;
        position: relative;
        z-index: 1;
      }
        .input-container.error {
          border-color: #e55600;
        }

        .input-container input,
        .input-container textarea,
        .input-container select {
          background: transparent;
          font-size: 1em;
          font-weight: 400;
          line-height: 1.3;
          border: 0px;
          padding: 0px;
          margin: 0px;
        }
        .input-container input:not([type="radio"]):not([type="checkbox"]),
        .input-container textarea,
        .input-container select {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        }

      .form-error {
        position: relative;
      }
        .errorMessage {
          color: #fff;
          background: #e36a21;
          border-radius: 3px;
          font-size: 12px;
          line-height: 1.3;
          padding: 3px 6px;
          margin: 3px 0px 0px;
        }

    .form-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0px 0px 0px -15px;
    }
      .form-button {
        padding: 22px 0px 0px 15px;
      }


.button-style {
  display: inline-block;
  color: #fff;
  background: #0294b5;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  line-height: 1.1;
  text-align: center;
  min-width: 8.5em;
  padding: 14px 20px 13px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
}
  .button-style:hover {
    background: #04a7cc;
  }

  .button-style.button-white,
  .button-style.button-white:hover {
    color: #2e3a41;
    background: #fff;
  }

  .button-style.button-orange {
    color: #fff;
    background: #e36a21;
  }
  .button-style.button-orange:hover {
    color: #fff;
    background: #ea8b29;
  }


ul.ul, ol.ol {
  margin-top: -6px;
  margin-bottom: 20px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    padding-left: 18px;
    margin-top: 0px;
    margin-bottom: 6px;
  }
  ul.ul li, ol.ol li {
    padding-top: 6px;
    padding-bottom: 0px;
    position: relative;
  }
    ul.ul li:before {
      content: "●";
      color: #2e3a41;
      display: inline-block;
      background-size: contain;
      font-weight: 600;
      margin-right: 0.7em;
      width: 0.45em;
    }
    ol.ol li:before {
      content: counters(ol-list-counter, ".") ". ";
      counter-increment: ol-list-counter;
      font-weight: 600;
      color: #2e3a41;
    }


/*a.zoom-image {
  display: inline-block;
  position: relative;
}
  a.zoom-image:before {
    content: "";
    background: url('/i/gallery-image-zoom.svg') 50% 50% no-repeat transparent;
    background-size: contain;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 28px;
    height: 28px;
    opacity: 1;

    -webkit-transition: width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s;
    -moz-transition: width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s;
    -o-transition: width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s;
    transition: width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s;
  }
    a.zoom-image:hover:before {
      width: 38px;
      height: 38px;
    }*/



.unwrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
  .unwrapper > div {
    width: 100vw;
    min-width: 100vw;
  }

.wrapper {
  width: 1200px;
  margin: 0px auto;
  padding: 0px;
  position: relative;
}


/* Structure */
.structure {
  /*overflow: hidden;*/
}
  /* Header */
  .header {
    color: #fff;
    background: #2e3a41;
    font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
    position: relative;
    z-index: 5;

    -webkit-transition: background .3s ease 0s;
    -moz-transition: background .3s ease 0s;
    -o-transition: background .3s ease 0s;
    transition: background .3s ease 0s;
  }
    .header.with-rotator {
      background: transparent;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
    }

    .header > .wrapper {
      padding-top: 12px;
      padding-bottom: 12px;
    }
      .header > .wrapper > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: -30px;
      }

        .header-logo {
          flex: 0 1 auto;
          padding-left: 30px;
        }
          .header-logo a {
            color: #fff;
          }
            .header-logo img {
              max-width: 100%;
            }


        .header-address-container {
          flex: 0 1 auto;
          padding-left: 30px;
          font-size: 16px;
        }
          .header-worktime {
            color: #e0e0e0;
            font-size: 14px;
          }


        .header-phones {
          flex: 0 1 auto;
          font-size: 30px;
          font-weight: 600;
          padding-left: 30px;
          white-space: nowrap;
        }
          .header-phones a {
            color: #fff;
          }


        .header-menu-container,
        .header-menu-button-container,
        .header-menu-checkbox {
          display: none;
        }


  /* Content block */
  .content {
    position: relative;
    /*overflow: hidden;*/
    z-index: 3;

    /*min-height: 3000px;*/
  }
    .main-rotator-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
      position: relative;
    }
      .main-rotator-container > div {
        width: 50%;
      }
        .main-rotator-container > div > div {
          width: 200%;
          min-width: 200%;
          height: 100%;
          position: relative;
        }
          .main-rotator-container > div:first-child > div:after {
            content: "";
            background: url('/i/rotator-arrow--down.png') no-repeat 50% 50%/contain transparent;
            display: block;
            width: 34px;
            height: 34px;
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 25px;
            margin: auto;
            animation: main-rotator-arrow-down 1.1s infinite ease-in-out;
            z-index: 2;
          }
            @keyframes main-rotator-arrow-down {
              0% { bottom: 25px }
              78% { bottom: 10px; }
              100% { bottom: 25px }
            }

          .main-rotator {
            color: #fff;
            height: 100%;
            min-height: 100vh;
            position: relative;
            overflow: hidden;
            z-index: 1;
          }
            .main-rotator .slick-list,
            .main-rotator .slick-track {
              height: 100%;
            }
            .main-rotator .slick-track {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: stretch;
            }

              .main-rotator-slide {
                height: 100%;
                position: relative;
                z-index: 1;
              }
                .main-rotator > .main-rotator-slide:first-child {
                  z-index: 2;
                }
                .main-rotator > .main-rotator-slide + div {
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                }

                .main-rotator .slick-track .main-rotator-slide {
                  height: auto !important;
                  float: none !important;
                }


                .main-rotator-slide > div {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: stretch;
                  height: 100%;
                }
                  .main-rotator-slide-code {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                    width: 50%;
                    /*order: 1;*/
                  }
                    .main-rotator-slide-code > div {
                      width: 200%;
                      min-width: 200%;
                      padding: 30px 0px 100px;
                    }
                      .main-rotator-slide-code > div > div > div {
                        width: 64%;
                        min-width: 64%;
                      }
                        .main-rotator-slide-code > div > div > div > div {
                          display: inline-block;
                          padding: 0px 40px 0px 60px;
                          position: relative;
                          /*z-index: 3;*/
                        }


                  .main-rotator-slide-image {
                    width: 50%;
                    opacity: 0;
                    /*z-index: 1;*/
                    /*order: 2;*/

                    -webkit-transition: opacity .3s ease .05s;
                    -moz-transition: opacity .3s ease .05s;
                    -o-transition: opacity .3s ease .05s;
                    transition: opacity .3s ease .05s;
                  }
                    .header.with-rotator + .content .main-rotator-slide-image {
                      opacity: 1;
                    }

                    .main-rotator-slide-image > div {
                      background: no-repeat 50% 50%/cover transparent;
                      width: calc(200% + 4px);
                      min-width: calc(200% + 4px);
                      height: 100%;
                      margin-left: -2px;
                      position: relative;

                      -webkit-filter: blur(10px);
                      filter: blur(10px);

                      -webkit-transition: background-image .3s ease 0s, -webkit-filter .3s ease 0s, filter .3s ease 0s;
                      -moz-transition: background-image .3s ease 0s, -webkit-filter .3s ease 0s, filter .3s ease 0s;
                      -o-transition: background-image .3s ease 0s, -webkit-filter .3s ease 0s, filter .3s ease 0s;
                      transition: background-image .3s ease 0s, -webkit-filter .3s ease 0s, filter .3s ease 0s;
                    }
                      .main-rotator-slide-image > div.no-blur {
                        -webkit-filter: blur(0px);
                        filter: blur(0px);
                      }

                      .main-rotator-slide-image > div:before {
                        content: "";
                        background: #2e3a41;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        opacity: .7;
                        z-index: 0;
                      }

                      .main-rotator-slide-image > div a {
                        content: "";
                        display: block;
                        font-size: 0px;
                        cursor: pointer;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        z-index: 1;
                      }


          .main-rotator-arrows {
            height: 0px;
          }
            .main-rotator-arrows > div {
              position: absolute;
              top: 0px;
              bottom: 0px;
              width: 40px;
              cursor: pointer;
              z-index: 4;
            }
              .main-rotator-arrows > div:after {
                content: "";
                background: url('/i/rotator-arrow--left.svg') no-repeat 50% 50%/10px auto #2e3a41;
                border-radius: 50%;
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                height: 40px;
                margin: auto;
              }
                .main-rotator-arrows > div:hover:after {
                  background-color: #e55600;
                }

            .main-rotator-arrows .slick-prev {
              left: 10px;
            }
            .main-rotator-arrows .slick-next {
              right: 10px;
            }
              .main-rotator-arrows .slick-next:after {
                background-image: url('/i/rotator-arrow--right.svg');
              }


      .main-rotator-callback {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: stretch;
      }
        .main-rotator-callback .wrapper {
          height: 100%;
        }
          .main-rotator-callback .wrapper > div {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
          }
            .main-rotator-callback .wrapper > div > div {
              width: 36%;
              min-width: 36%;
              padding: 15px 60px 100px 0px;
            }
              .main-rotator-callback .form-block-container {
                color: #fff;
                font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
                background: rgba(250,100,10,.5)/*rgba(227,106,33,.5)*/;
                padding: 30px 35px 35px;
                position: relative;
                z-index: 3;
              }
                .main-rotator-callback .form-block-container > .subsubsubheader-min {
                  font-size: 20px;
                  font-weight: 600;
                }

                .main-rotator-callback .form-label {
                  font-size: 14px;
                }
                  .main-rotator-callback .form-row-checkbox .form-label {
                    font-size: 12px;
                  }

                  .main-rotator-callback .form-label a,
                  .main-rotator-callback span.required {
                    color: #fff;
                  }

                .main-rotator-callback .form-row-checkbox .form-field > span,
                .main-rotator-callback .form-row-checkbox .form-field > span label:after {
                  border-color: #fff;
                }

                .main-rotator-callback .input-container,
                .main-rotator-callback .input-container input {
                  font-size: 16px;
                }

                .main-rotator-callback .input-container.error {
                  border-color: #04a7cc;
                }
                .main-rotator-callback .errorMessage {
                  background: #0294b5;
                }


                .main-rotator-callback .form-button .button-style {
                  font-size: 16px;
                }


              .main-rotator-callback .rotator-callback-show-button {
                display: none;
                max-width: 500px;
                padding: 20px 10px;
                margin: auto;
              }
                .main-rotator-callback .rotator-callback-show-button .button-style {
                  display: block;
                  font-weight: 600;
                }


    .subheader,
    .subsubheader,
    .subsubsubheader,
    .subsubsubheader-min {
      position: relative;
      font-size: 42px;
      font-weight: 600;
      line-height: 1.2;
      padding: 40px 0px 38px;
      text-align: left;
      z-index: 2;
    }
      h1.subheader {
        font-size: 46px;
      }

    .subsubheader {
      font-size: 40px;
      padding: 20px 0px 30px;
    }

    .subsubsubheader,
    .subsubsubheader-min {
      font-size: 30px;
      font-weight: 700;
      padding: 18px 0px;
    }
      h1.subsubsubheader {
        font-size: 34px;
        padding: 25px 0px 35px;
      }

    .subsubsubheader-min {
      padding-top: 0px !important;
    }


    .text-container {
      padding: 0px 0px 30px;
      position: relative;
      z-index: 1;
    }


    .gallery-block-container > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      color: #3f72ab;
      font-weight: 600;
      font-style: italic;
      padding: 0px 0px 20px;
      margin: -24px 0px 0px -24px;
      position: relative;
    }
      .gallery-item {
        padding: 24px 0px 0px 24px;
        position: relative;
      }
      .gallery-item.gallery-item-width-2 { width: 50%; }
      .gallery-item.gallery-item-width-3 { width: 33.33333333%; }
      .gallery-item.gallery-item-width-4 { width: 25%; }

        .gallery-item img {
          width: 100%;
        }

        .gallery-item-notice {
          font-size: 14px;
          line-height: 1.3;
          text-align: center;
          width: 100%;
          padding: 5px 0px 0px;
        }

      .gallery-notice {
        text-align: center;
        margin: -10px 0px 0px !important;
      }


    .gallery-mini-block-container {
      color: #fff;
      font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
      font-size: 14px;
      padding: 10px 0px 40px;
    }
      .gallery-mini-block-container > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: stretch;
        margin: -24px 0px 0px -24px;
      }
        .gallery-mini-block-container > div > div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
          width: 25%;
          padding: 24px 0px 0px 24px;
        }
          .gallery-mini-block-container > div > div > div {
            width: 50%;
          }
            .gallery-mini-block-container > div > div > div:first-child > div {
              width: 200%;
              min-width: 200%;
              height: 100%;
              position: relative;
              overflow: hidden;
            }
              .gallery-mini-block-container > div > div > div:first-child span {
                background: no-repeat 50% 50%/cover transparent;
                display: block;
                height: 100%;
                position: relative;
              }
              .gallery-mini-block-container > div > div > div:first-child span.active {
                -webkit-transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s;
                -moz-transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s;
                -o-transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s;
                transition: -webkit-transform .3s ease 0s, -moz-transform .3s ease 0s, -ms-transform .3s ease 0s, -o-transform .3s ease 0s, transform .3s ease 0s;
              }
                .gallery-mini-block-container > div > div > div:first-child > div:hover span.active {
                  -webkit-transform: scale(1.07);
                  -moz-transform: scale(1.07);
                  -ms-transform: scale(1.07);
                  -o-transform: scale(1.07);
                  transform: scale(1.07);
                }

                .gallery-mini-block-container > div > div > div:first-child span:before {
                  content: "";
                  background: #2e3a41;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  opacity: .3;
                  z-index: 1;
                }
                .gallery-mini-block-container > div > div > div:first-child span:after {
                  content: "";
                  display: block;
                  height: 0px;
                  padding: 60% 0% 0%;
                }

              .gallery-mini-block-container > div > div > div:first-child span + a {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                cursor: pointer;
                z-index: 3;
              }


          .gallery-mini-block-container > div > div > div + div {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: stretch;
          }
            .gallery-mini-block-container > div > div > div + div > div {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-start;
              width: 200%;
              min-width: 200%;
              padding: 10px 22px 25px;
            }
              .gallery-mini-block-container > div > div > div + div > div > div:first-child {
                font-size: 16px;
                font-weight: 600;
                margin: 0px 0px 10px;
                position: relative;
                z-index: 2;
              }

              .gallery-mini-block-container > div > div > div + div > div > div + div {
                margin: -5px 0px 0px;
                position: relative;
                z-index: 2;
              }


                .gallery-mini-block-container .list-view-item-image {
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: space-between;
                  align-content: flex-start;
                  align-items: flex-start;
                  padding: 0px 4px 0px 0px;
                }
                  .gallery-mini-block-container .list-view-item-image > span {
                    background: none;
                    height: auto;
                    padding: 4px 0px 0px 4px;
                  }
                    .gallery-mini-block-container .list-view-item-image > span:first-child {
                      width: 100%;
                    }
                    .gallery-mini-block-container .list-view-item-image > span + span {
                      width: 33.333333%;
                    }
                    .gallery-mini-block-container .list-view-item-image > span:nth-child(n + 5) {
                      display: none;
                    }

                    .gallery-mini-block-container .list-view-item-image a {
                      display: block;
                    }
                      .gallery-mini-block-container .list-view-item-image > span + span a.zoom-image:hover:before {
                        top: 20%;
                        left: 20%;
                        width: 60%;
                        height: 60%;
                      }

                    .gallery-mini-block-container .list-view-item-image img {
                      width: 100%;
                    }


    .js-tabs {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-end;
      font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
      font-size: 18px;
      line-height: 1.3;
      padding: 0px 0px 25px;
      position: relative;
      z-index: 1;
    }
      .js-tabs > div {
        flex: 1 1 auto;
        padding: 5px 0px 0px;
      }
        .js-tabs > div a {
          display: block;
          color: #9dadb8;
          border-bottom: 3px solid #d3dbe0;
          font-weight: 600;
          padding: 10px 5px;
          /*white-space: nowrap;*/
          cursor: pointer;
        }
          .js-tabs > div a:hover {
            color: #04a7cc;
          }
          .js-tabs > div a.active {
            color: #04a7cc;
            border-color: #04a7cc;
          }


    .about-block-container {
      color: #fff;
      background: #2e3a41/*#e36a21*/;
      padding: 50px 0px 35px;
      position: relative;
    }
      .about-block-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        padding: 0px 0px 50px;
      }
        .about-block-wrapper > div {
          width: 60%;
          padding: 0px 50px 0px 0px;
        }
          .about-block-wrapper:after {
            content: "";
            background: url('/i/about-bg.svg') no-repeat 50% 50%/contain transparent;
            display: block;
            width: 40%;
            margin: 0px 0px 30px;
            opacity: .9;
          }


    .advantages-block-container {
      font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
      font-size: 17px;
      padding: 0px 0px 30px;
    }
      .advantages-block-container > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: stretch;
        margin: -40px 0px 0px -80px;
      }
        .advantages-block-container > div > div {
          width: 33.33333333%;
          padding: 40px 0px 0px 80px;
        }
          .advantages-block-container > div > div > div {
            background: rgba(255,255,255,.18);
            border-radius: 4px;
            box-shadow: 0px 0px 20px 0px rgba(0,0,0,.3);
            height: 100%;
            padding: 18px 20px 20px;
          }
            .advantages-block-container > div > div > div > div:first-child {
              width: 25%;
              padding: 0px 0px 10px;
            }
              .advantages-block-container > div > div > div > div:first-child > span {
                display: block;
                background: no-repeat 50% 50%/contain transparent;
                position: relative;
              }
                .advantages-block-container > div > div > div > div:first-child > span:after {
                  content: "";
                  display: block;
                  height: 0px;
                  padding: 100% 0% 0%;
                }

          .advantages-block-container > div > div > div > div + div > div:first-child {
            font-size: 20px;
            font-weight: 600;
            padding: 0px 0px 4px;
          }


    .why-we-block-container {
      padding: 0px 0px 40px;
      position: relative;
    }
      .why-we-block-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: stretch;
        font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
        font-size: 15px;
        margin: -30px 0px 0px -50px;
      }
        .why-we-block-wrapper > div {
          width: 25%;
          padding: 30px 0px 18px 50px;
          position: relative;
        }
          /*.why-we-block-wrapper > div:not(:nth-child(4n+1)):before {
            content: "";
            background: red;
            display: block;
            position: absolute;
            top: 0px;
            left: -25px;
            bottom: 0px;
            width: 1px;
          }*/

          .why-we-block-wrapper > div > div:first-child {
            font-size: 0px;
            background: no-repeat 50% 50%/cover #eee;
            margin: 0px 0px 12px;
          }
            .why-we-block-wrapper > div > div:first-child:after {
              content: "";
              display: block;
              height: 0px;
              padding: 65% 0% 0%;
            }

          .why-we-block-wrapper > div > div + div > div:first-child {
            font-size: 18px;
            font-weight: 600;
            padding: 0px 0px 3px;
          }


      .why-we-preview {
        -webkit-transition: /*max-height 0s ease 0s, */opacity .5s ease 0s;
        -moz-transition: /*max-height 0s ease 0s, */opacity .5s ease 0s;
        -o-transition: /*max-height 0s ease 0s, */opacity .5s ease 0s;
        transition: /*max-height 0s ease 0s, */opacity .5s ease 0s;
        opacity: 1;
        overflow: hidden;
      }
        .why-we-block-container.opened .why-we-preview {
          /*max-height: 0px !important;*/
          opacity: .1;

          -webkit-transition: /*max-height .6s ease 0s, */opacity .5s ease .6s;
          -moz-transition: /*max-height .6s ease 0s, */opacity .5s ease .6s;
          -o-transition: /*max-height .6s ease 0s, */opacity .5s ease .6s;
          transition: /*max-height .6s ease 0s, */opacity .5s ease .6s;
        }


      .why-we-groups {
        display: none;
        position: relative;
        z-index: 1;
      }
        .why-we-block-container.opened .why-we-groups {
          display: block;
        }

        .why-we-year {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-weight: 600;
          padding: 15px 0px 20px;
        }
          .why-we-year:before,
          .why-we-year:after {
            content: "";
            flex-grow: 1;
            background: #04a7cc;
            border-radius: 8px;
            height: 2px;
          }

          .why-we-year span {
            padding: 0px 20px;
          }


      .why-we-block-container.opened .why-we-button-show,
      .why-we-button-hide,
      .why-we-button-hide-sticky {
        display: none;
      }
      .why-we-block-container.opened .why-we-button-hide,
      .why-we-block-container.opened .why-we-button-hide-sticky {
        display: block;
      }

      .why-we-button-show,
      .why-we-button-hide {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.1;
        text-align: center;
        padding: 10px 0px 0px;
        cursor: pointer;
      }
        .why-we-button-show span,
        .why-we-button-hide span {
          display: inline-block;
          color: #e55600;
          border-bottom: 1px dashed #e55600;
        }
          .why-we-button-show:hover span,
          .why-we-button-hide:hover span {
            border-bottom-color: transparent;
          }

      .why-we-button-show {
        padding-bottom: 20px;
      }

      .why-we-button-hide-sticky {
        font-size: 0px;
        text-align: center;
        position: sticky;
        bottom: -10px;
        padding: 15px 0px 20px;
        z-index: 2;
      }
        .why-we-button-hide-sticky span {
          background: url('/i/cross-icon.svg') no-repeat 50% 50%/14px auto #e36a21;
          border-radius: 50%;
          display: inline-block;
          width: 40px;
          height: 40px;
          cursor: pointer;
        }


    .rooms-rotator-container {
      padding: 15px 50px 50px 50px;
      position: relative;
    }
      .rooms-rotator-container .item {
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        width: 100%;
      }
        .rooms-rotator-container > div > .item + .item {
          display: none;
        }

        .rooms-rotator-container .item > div:first-child {
          width: 50%;
        }
          .rooms-rotator-container .item > div:first-child > div {
            background: no-repeat 50% 50%/cover transparent;
            height: 100%;
          }
            .rooms-rotator-container .item > div:first-child > div:after {
              content: "";
              display: block;
              height: 0px;
              padding: 75% 0% 0%;
            }

        .rooms-rotator-container .item > div + div {
          /*line-height: 1.3;*/
          width: 50%;
          padding-left: 40px;
        }
          .room-parameters {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: flex-start;
            font-size: 16px;
            padding: 0px 0px 15px;
            margin: -8px 0px 0px -20px;
          }
            .room-parameters > div {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              padding: 8px 0px 0px 20px;
            }
              .room-parameters > div > div:first-child {
                background: no-repeat 50% 50%/contain transparent;
                width: 22px;
                min-width: 22px;
                height: 22px;
                margin: 0px 10px 0px 0px;
              }
              .room-parameters > div:first-child > div:first-child {
                background-image: url('/i/room-parameter-house.svg');
              }

              .room-parameters > div > div + div span,
              .room-parameters > div > div + div a {
                font-weight: 600;
              }


        .room-notice {
          font-size: 16px;
          padding: 0px 0px 18px;
        }
          .room-notice > :last-child {
            margin-bottom: 0px;
          }


        .room-price {
          vertical-align: baseline;
          padding: 0px 0px 15px;
        }
          .room-price span {
            vertical-align: baseline;
          }
          .room-price .price {
            color: #e55600;
            font-size: 28px;
            font-weight: 700;
          }
          .room-price .price-cur {
            color: #e55600;
          }


      .rooms-rotator-arrows {
        height: 0px;
      }
        .rooms-rotator-arrows div {
          position: absolute;
          top: 15px;
          bottom: 50px;
          width: 40px;
          cursor: pointer;
          z-index: 4;
        }
          .rooms-rotator-arrows > div:after {
            content: "";
            background: url('/i/rotator-arrow--left.svg') no-repeat 50% 50%/10px auto #2e3a41;
            border-radius: 50%;
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            height: 40px;
            margin: auto;
          }
            .rooms-rotator-arrows > div:hover:after {
              background-color: #e55600;
            }

        .rooms-rotator-arrows .slick-prev {
          left: 0px;
        }
        .rooms-rotator-arrows .slick-next {
          right: 0px;
        }
          .rooms-rotator-arrows .slick-next:after {
            background-image: url('/i/rotator-arrow--right.svg');
          }


    .product-fast-order-name {
      font-weight: 600;
      padding: 0px 0px 5px;
    }
    .product-fast-order-section2 {
      font-size: 16px;
      padding: 0px 0px 22px;
    }


    .catalog-buildings-container {
      padding: 0px 0px 40px;
    }
      .catalog-buildings-filter {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: center;
        font-size: 18px;
        line-height: 1.3;
        padding: 10px 0px 30px;
        margin: -12px 0px 0px -20px;
        position: relative;
        z-index: 1;
      }
        .catalog-buildings-filter > div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: #3f72ab;
          border-bottom: 2px solid #a5b9d0;
          padding: 12px 2px 2px;
          margin: 0px 0px 0px 20px;
          white-space: nowrap;
          cursor: pointer;
        }
          .catalog-buildings-filter > div:hover {
            color: #e55600;
          }
          .catalog-buildings-filter > div.active {
            color: #e55600;
            border-color: #e55600;
            font-weight: 600;
          }
          .catalog-buildings-filter > div:not([data-filter="all"]).active {
            margin-right: 20px;
          }
            .catalog-buildings-filter > div:not([data-filter="all"]).active:after {
              content: "";
              background: url('/i/cross-icon.svg') no-repeat 50% 50%/8px auto #e55600;
              border-radius: 3px;
              width: 14px;
              min-width: 14px;
              height: 14px;
              cursor: pointer;
              margin: 0px -20px 0px 6px;
            }


      .catalog-buildings {
        font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
        font-size: 16px;
        position: relative;
      }
        .catalog-buildings > div {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-content: flex-start;
          align-items: stretch;
          margin: -40px 0px 0px -40px;
          position: relative;
        }
          .catalog-buildings .item {
            width: 25%;
            padding: 40px 0px 0px 40px;
          }
            .catalog-buildings .item > div {
              background: #fff;
              border-radius: 4px;
              box-shadow: 0px 0px 20px 0px rgba(0,0,0,.1);
              height: 100%;
              padding: 20px;
            }
              .catalog-buildings .item > div > div:first-child {
                padding: 0px 0px 20px;
                position: relative;
              }
                .catalog-buildings .item > div > div:first-child > span {
                  display: block;
                  background: no-repeat 50% 50%/cover transparent;
                  position: relative;
                }
                  .catalog-buildings .item > div > div:first-child > span:after {
                    content: "";
                    display: block;
                    height: 0px;
                    padding: 75% 0% 0%;
                  }


                .catalog-buildings .item .labels {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: flex-end;
                  position: absolute;
                  left: 5px;
                  right: 0px;
                  bottom: 20px;
                  z-index: 1;
                }
                  .catalog-buildings .item .labels > div {
                    margin: 0px 5px 5px 0px;
                  }
                    .catalog-buildings .item .labels .gallery-images a ~ a {
                      display: none;
                    }
                      .catalog-buildings .item .labels .gallery-images a span {
                        display: block;
                        font-size: 0px;
                        background: #2e3a41;
                        border: none;
                        border-radius: 10px;
                        padding: 8px;
                        cursor: pointer;
                      }
                        .catalog-buildings .item .labels .gallery-images a span:after {
                          content: "";
                          background: url('/i/catalog-building-gallery.svg') no-repeat 50% 50%/contain transparent;
                          display: block;
                          width: 18px;
                          height: 18px;
                        }


                    .catalog-buildings .item .labels .no-comission > div {
                      color: #fff;
                      background: #e55600;
                      border: none;
                      border-radius: 10px;
                      font-size: 13px;
                      line-height: 1.1;
                      text-align: center;
                      padding: 5px 10px 6px;
                      position: relative;
                    }


              .catalog-buildings .item > div > div:first-child + div > div:first-child {
                font-weight: 600;
                min-height: 48px;
              }

              .catalog-buildings .item > div > div:first-child + div > div:first-child + div {
                font-size: 13px;
                padding: 10px 0px 0px;
              }
                .catalog-buildings .item > div > div:first-child + div > div:first-child + div > div {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                }
                .catalog-buildings .item .catalog-building-free-consultation {
                  font-weight: 600;
                  padding: 0px 0px 8px;
                }

                  .catalog-buildings .item > div > div:first-child + div > div:first-child + div > div:before {
                    content: "";
                    background: no-repeat 50% 50%/contain transparent;
                    width: 20px;
                    min-width: 20px;
                    height: 20px;
                    margin: 0px 6px 0px 0px;
                  }
                  .catalog-buildings .item .catalog-building-free-consultation:before {
                    display: none;
                  }
                  .catalog-buildings .item .catalog-building-date:before {
                    background-image: url('/i/catalog-building-date.svg') !important;
                  }
                  .catalog-buildings .item .catalog-building-square:before {
                    background-image: url('/i/catalog-building-square.svg') !important;
                  }
                  .catalog-buildings .item .catalog-building-price:before {
                    background-image: url('/i/catalog-building-price.svg') !important;
                  }

                  .catalog-buildings .item > div > div:first-child + div > div:first-child + div > div + div {
                    margin: 4px 0px 0px;
                  }

                  .catalog-buildings .item > div > div:first-child + div > div:first-child + div > div span {
                    vertical-align: baseline;
                  }
                  .catalog-buildings .item > div > div:first-child + div > div:first-child + div > div span span {
                    font-size: 15px;
                    font-weight: 600;
                    white-space: nowrap;
                  }


              .catalog-buildings .item > div > div:first-child + div + div {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                align-content: flex-start;
                align-items: flex-start;
                padding: 18px 0px 0px;
                margin: 0px -10px 0px -15px;
              }
                .catalog-buildings .item > div > div:first-child + div + div > div {
                  text-align: center;
                  margin: 6px 0px 0px 5px;
                }
                  .catalog-buildings .item > div > div:first-child + div + div .button-style {
                    font-size: 13px;
                    min-width: unset;
                    padding: 9px 10px 10px;
                  }

                  /*.catalog-buildings .item > div > div:first-child + div + div .catalog-building-tour {
                    font-size: 0px;
                    color: transparent;
                    padding: 4px;
                  }
                    .catalog-buildings .item > div > div:first-child + div + div .catalog-building-tour:after {
                      content: "";
                      display: block;
                      background: url('/i/catalog-building-tour.svg') no-repeat 50% 50%/contain transparent;
                      width: 25px;
                      height: 25px;
                    }*/


    .callback-content-form-container .form-block-container {
      color: #fff;
      background: transparent;
      font-size: 16px;
    }
      .callback-content-form-container .form-label a,
      .callback-content-form-container span.required {
        color: #fff;
      }

      .callback-content-form-container .form-row-checkbox .form-field > span,
      .callback-content-form-container .form-row-checkbox .form-field > span label:after {
        border-color: #fff;
      }

      .callback-content-form-container .input-container.error {
        border-color: #04a7cc;
      }
      .callback-content-form-container .errorMessage {
        background: #0294b5;
      }


    .callback-teammember {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
      font-size: 16px;
      line-height: 1.3;
      padding: 0px 0px 30px;
    }
      .callback-teammember > div:first-child {
        width: 25%;
        min-width: 25%;
      }
        .callback-teammember > div:first-child span {
          display: block;
          background: no-repeat 50% 50%/cover transparent;
          border-radius: 50%;
        }
          .callback-teammember > div:first-child span:after {
            content: "";
            display: block;
            height: 0px;
            padding: 100% 0% 0%;
          }


      .callback-teammember > div + div {
        padding: 0px 0px 0px 30px;
      }
        .callback-teammember > div + div > div:first-child {
          font-size: 20px;
          font-weight: 600;
          padding: 0px 0px 10px;
        }


    .how-to-buy-contents .form-block-container,
    .callback-footer-form-container .form-block-container {
      font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
      font-size: 15px;
      background: transparent;
      padding: 20px 40px 40px;
    }


    .advantages2-block-container {
      font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
      font-size: 18px;
      padding: 0px 0px 30px;
    }
      .advantages2-block-container > div > div + div {
        padding: 40px 0px 0px;
      }
        .advantages2-block-container > div > div > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
          .advantages2-block-container > div > div > div > div:first-child {
            width: 48px;
            min-width: 48px;
            margin: 0px 25px 0px 0px;
          }
            .advantages2-block-container > div > div > div > div:first-child span {
              display: block;
              background: no-repeat 50% 50%/contain transparent;
            }
              .advantages2-block-container > div > div > div > div:first-child span:after {
                content: "";
                display: block;
                height: 0px;
                padding: 100% 0% 0%;
              }


          .advantages2-block-container > div > div > div > div + div > div + div {
            font-size: 14px;
            margin: 10px 0px 0px;
          }



  #privacy-popup-content,
  #catalog-phone-popup-content {
    display: none;
  }



  /* Footer */
  .footer {
    color: #fff;
    font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
    font-size: 16px;
    background: #2e3a41;
    position: relative;
    z-index: 1;
  }
    .footer > .wrapper > div:first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 38px 0px 40px;
      margin-left: -20px;
    }
      .footer > .wrapper > div:first-child > div {
        flex: 0 1 auto;
        padding-left: 20px;
      }
        .footer-logo a {
          color: #fff;
        }
          .footer-logo img {
            max-width: 90%;
          }


      .footer-address-container .footer-worktime {
        color: #e0e0e0;
        font-size: 14px;
        padding: 15px 0px 0px;
      }


      .footer-phones-container {
        text-align: center;
      }
        .footer-phones-container > div:first-child {
          font-size: 26px;
          font-weight: 600;
        }
          .footer-phones-container > div:first-child a {
            color: #fff;
            line-height: 1.1;
            white-space: nowrap;
            cursor: default;
          }


        .footer-social {
          display: inline-flex;
          flex-flow: row wrap;
          justify-content: center;
          align-items: center;
          padding: 10px 0px 0px;
          margin-left: -25px;
        }
          .footer-social > div {
            padding: 10px 0px 0px 25px;
          }
            .footer-social a {
              color: transparent;
              font-size: 0px;
              display: block;
              width: 25px;
              height: 25px;
              position: relative;
              opacity: .7;
            }
            .footer-social a:hover {
              opacity: 1;
            }

            .footer-social a.fb,
            .footer-social a.ok {
              width: 15px;
            }

              .footer-social a:after {
                content: "";
                display: block;
                background: url('/i/social-icons-full.svg') no-repeat -5px 0px transparent;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                margin: auto;
              }
              .footer-social a.tw:after {
                background-position: 0px -25px;
              }
              .footer-social a.ig:after {
                background-position: -25px 0px;
              }
              .footer-social a.vk:after {
                background-position: -50px 0px;
              }
              .footer-social a.ok:after {
                background-position: -55px -25px;
              }
              .footer-social a.wa:after {
                background-position: -75px 0px;
              }
              .footer-social a.youtube:after {
                background-position: -25px -25px;
              }
              .footer-social a.tg:after {
                background-position: -75px -25px;
              }


    .footer-copyrights {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      font-size: 14px;
      border-top: 1px solid #51575a;
      padding: 30px 0px 32px;
    }